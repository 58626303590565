<template>
    <div id="rc-c">
        <div id="rc-header-c">
            <h1 class="typo-headline-6">
                Calculadora de autonomía financiera
            </h1>
            <span class="typo-body-2">
                Herramienta para hacer una previsión de la edad a la que alcazarás la autonomía financiera, en función de tu ahorro e inversiones.
            </span>
        </div>

        <div id="rc-input-output-c">
            <div id="rc-form-c">
                
                <div id="rc-input-c">
                    <div class="rc-input-section-c">
                        <div class="rc-section-title-container">
                            <span class="typo-capsheading-6 rc-section-title">
                                VIDA
                            </span>
                        </div>

                        <div class="rc-input-group">
                        
                            <DigitOutlineInput
                            :id="'rc-input-age'"
                            :label="'Edad actual'"
                            @inputChanged="(new_val)=>{this.current_age = new_val}"
                            class="material-input"
                            />

                            <DigitOutlineInput
                            :id="'rc-input-expectancy'"
                            :label="'Esperanza de vida'"
                            @inputChanged="(new_val)=>{this.life_expectancy = new_val}"
                            class="material-input"
                            />
                            
                        </div>
                    </div>

                    <div class="rc-input-section-c">
                        <div class="rc-section-title-container">
                            <span class="typo-capsheading-6 rc-section-title">
                                CAPITAL
                            </span>
                        </div>

                        <div class="rc-input-group">

                            <DigitOutlineInput
                            :id="'rc-input-init-capital'"
                            :label="'Capital inicial'"
                            @inputChanged="(new_val)=>{this.init_capital = new_val}"
                            class="material-input"
                            />

                            <DigitOutlineInput
                            :id="'rc-input-annual-earnings'"
                            :label="'Ingresos netos anuales'"
                            @inputChanged="(new_val)=>{this.annual_capital = new_val}"
                            class="material-input"
                            />

                            <DigitOutlineInput
                            :id="'rc-input-annual-spendings'"
                            :label="'Gastos anuales'"
                            @inputChanged="(new_val)=>{this.annual_expenses = new_val}"
                            class="material-input"
                            />

                            <DigitOutlineInput
                            :id="'rc-input-annual-inflation'"
                            :label="'Inflación media anual'"
                            @inputChanged="(new_val)=>{this.annual_inflation = new_val}"
                            class="material-input"
                            />

                        </div>
                    </div>

                    <div class="rc-input-section-c">
                        <div class="rc-section-title-container">
                            <span class="typo-capsheading-6 rc-section-title">
                                INVERSION
                            </span>
                        </div>

                        <div class="rc-input-group">

                            <DigitOutlineInput
                            :id="'rc-input-initial-investment'"
                            :label="'Capital inicial inversión'"
                            @inputChanged="(new_val)=>{this.init_investment = new_val}"
                            class="material-input"
                            />

                            <DigitOutlineInput
                            :id="'rc-input-yearly-investment'"
                            :label="'Inversiones anuales'"
                            @inputChanged="(new_val)=>{this.annual_investment = new_val}"
                            class="material-input"
                            />

                            <DigitOutlineInput
                            :id="'rc-input-average-yearly-return'"
                            :label="'Rentabilidad anual'"
                            @inputChanged="(new_val)=>{this.annual_returns = new_val}"
                            class="material-input"
                            />

                            <DigitOutlineInput
                            :id="'rc-input-investment-costs'"
                            :label="'Costes fijos anuales de inversión'"
                            @inputChanged="(new_val)=>{this.annual_investment_costs = new_val}"
                            class="material-input"
                            />
                        </div>
                    </div>
                </div>

                <div id="rc-input-submit-button" class="typo-button" @click="createTable">
                    CALCULAR
                </div>

            </div>
            <div v-if="chart_generated" id="rc-output-c">
                <p class="rc-retire-text">En función de la información proporcionada:<br><br>El punto donde coinciden las curvas de “capital necesario para alcanzar la autonomía” y “capital de ahorros e inversiones”, señala la edad en la que podrías retirarte con tu propio capital, siendo así financieramente independiente.<br><br> En tu caso: <span id="rc-output-age" class="typo-headline-6"> {{intersection_age_processed}}</span></p>

                <p class="rc-retire-text">El punto donde coinciden las curvas de “capital necesario para alcanzar la autonomía” y “capital de inversión”, señala la edad en la que podrías retirarte con tu propio capital obtenido exclusivamente a partir de tus inversiones.<br><br> En tu caso: <span id="rc-output-age" class="typo-headline-6"> {{investment_age_processed}}</span></p>

                <p class="rc-retire-text">El punto donde coinciden las curvas de “capital necesario para alcanzar la autonomía” y “capital de ahorro”, señala la edad en la que podrías retirarte con tu propio capital obtenido exclusivamente a partir de tus ahorros.<br><br> En tu caso: <span id="rc-output-age" class="typo-headline-6"> {{savings_age_processed}}</span></p>
            </div>
        </div>

        <div id="rc-chart-c">
        </div>
    </div>
</template>

<script>
import Highcharts from "highcharts";

import { mapGetters } from "vuex";
import DigitOutlineInput from '@/components/helpers/newMaterial/DigitOutlineInput.vue';

export default {
    name: 'RetirementCalculator',
    components: {
        DigitOutlineInput
    },
    metaInfo() {
    return {
      title: 'Calculadora de autonomía financiera Eurekers',
      meta:[
        { name: 'description', content: "La calculadora de autonomía financiera permite conocer cuánto tiempo y dinero necesitas para alcanzar la autonomía financiera, ofrecida por Eurekers"}
      ]
    }
  },

    data() {
    return {
        current_age_raw: 0,
        life_expectancy_raw: 0,
        init_capital_raw: 0,
        annual_capital_raw: 0,
        annual_spendings_raw: 0,
        annual_inflation_raw: 0,
        init_investment_raw: 0,
        annual_investment_raw: 0,
        annual_returns_raw: 0,
        annual_investment_costs_raw: 0,

        chart_generated: false,
        intersection_age: -1,
        investment_age: -1,
        savings_age: -1
    };
    },
    mounted() {
    },

    computed: {

        //Vuex Getters
        ...mapGetters(['is_mobile_device']),

        //Access calc data
		current_age: {
			get() {
				return parseFloat(this.current_age_raw);
			},
			set(new_value) {
				this.current_age_raw = new_value == '' ? 0 : new_value;
			},
		},

		life_expectancy: {
			get() {
				return parseFloat(this.life_expectancy_raw);
			},
			set(new_value) {
				this.life_expectancy_raw = new_value == '' ? 0 : new_value;
			},
		},

		init_capital: {
			get() {
				return parseFloat(this.init_capital_raw);
			},
			set(new_value) {
				this.init_capital_raw = new_value == '' ? 0 : new_value;
			},
		},

		annual_capital: {
			get() {
				return parseFloat(this.annual_capital_raw);
			},
			set(new_value) {
				this.annual_capital_raw = new_value == '' ? 0 : new_value;
			},
		},

		annual_spendings: {
			get() {
				return parseFloat(this.annual_spendings_raw);
			},
			set(new_value) {
				this.annual_spendings_raw = new_value == '' ? 0 : new_value;
			},
		},

		annual_inflation: {
			get() {
				return parseFloat(this.annual_inflation_raw);
			},
			set(new_value) {
				this.annual_inflation_raw = new_value == '' ? 0 : new_value;
			},
		},

		init_investment: {
			get() {
				return parseFloat(this.init_investment_raw);
			},
			set(new_value) {
				this.init_investment_raw = new_value == '' ? 0 : new_value;
			},
		},

		annual_investment: {
			get() {
				return parseFloat(this.annual_investment_raw);
			},
			set(new_value) {
				this.annual_investment_raw = new_value == '' ? 0 : new_value;
			},
		},

		annual_returns: {
			get() {
				return parseFloat(this.annual_returns_raw);
			},
			set(new_value) {
				this.annual_returns_raw = new_value == '' ? 0 : new_value;
			},
		},

		annual_investment_costs: {
			get() {
				return parseFloat(this.annual_investment_costs_raw);
			},
			set(new_value) {
				this.annual_investment_costs_raw = new_value == '' ? 0 : new_value;
			},
		},

        //Access intersection results
        intersection_age_processed() {
            return  this.intersection_age >= 0 ? this.intersection_age + " años" : "No alcanzará la independencia financiera con los datos introducidos";
        },

        investment_age_processed() {
            return this.investment_age >= 0 ? this.investment_age + " años" : "No alcanzará la independencia financiera con los datos introducidos";
        },

        savings_age_processed() {
            return this.savings_age >= 0 ? this.savings_age + " años" : "No alcanzará la independencia financiera con los datos introducidos";
        }
    },

    methods: {
        //Calcs money needed plot from current_age to life_expectancy based on expenses adjusted for inflation
        getRetirementNeedsYPlot: function(current_age, life_expectancy, annual_expenses) {
            const from_to_years = life_expectancy - current_age;
            const res = [];

            const getRetirementNeedsForYear = function(year) {
                const years_left = life_expectancy - year;
                const non_inflationary_needs = years_left * annual_expenses;
                return non_inflationary_needs;
            }

            for(let i = 0; i < from_to_years; i++) {
                const x = current_age + i;
                res[i] = getRetirementNeedsForYear(x);
            }
            
            return res;
        },

        //Gets savings evolution plot based on inital ammount, annual earnings, annual expenses and adjusted for inflation
        getSavingsCapitalYPlot: function(current_age, life_expectancy, init_capital, annual_capital, annual_expenses, annual_inflation_rate) {
            const from_to_years = life_expectancy - current_age;
            const res = [];
            const annual_savings = annual_capital - annual_expenses;

            const getSavingsOnYear = function(year) {
                const non_inflationary_savings = init_capital + year * annual_savings;
                const cumulative_inflation = Math.pow((1 - (annual_inflation_rate * 0.01)), year);
                const inflationary_savings = non_inflationary_savings * cumulative_inflation;
                return inflationary_savings;
            }

            for(let i = 0; i < from_to_years; i++) {
                res[i] = getSavingsOnYear(i);
            }

            return res;
        },

        //Calcs retirement age from savings
        setSavingsNeedsIntersection(current_age, needs_plot, savings_plot) {
            this.savings_age = -1;
            for(let i = 0; i < savings_plot.length; i++) {
                if(savings_plot[i] >= needs_plot[i]) {
                    this.savings_age = current_age + i;
                    return;
                }
            }
        },

        //Gets investments evolution plot based on initial ammount, annual contribution, annual return rate and adjusted for inflation
        getInvestmentCapitalYPlot: function(current_age, life_expectancy, init_investment, annual_investment, annual_returns, annual_investment_costs, annual_inflation_rate) {
            const from_to_years = life_expectancy - current_age;
            const res = [];

            const getInvestmentCapitalOnYear = function(year) {
                //Initial ammount
                const p = init_investment;
                //Annual contribution
                const a = annual_investment - annual_investment_costs;
                //Interest rate
                const i = annual_returns / 100.0;
                //Years
                const n = year - current_age;

                const first_term = p + a / i;
                const second_term = Math.pow(1 + i, n);
                const third_term = a / i;
                const full_equation = first_term * second_term - third_term;
                const cumulative_inflation = Math.pow((1 - (annual_inflation_rate * 0.01)), n);
                const inflationary_investments = full_equation * cumulative_inflation;
                return inflationary_investments;
            }

            for(let i = 0; i < from_to_years; i++) {
                const x = current_age + i;
                const num_contender = getInvestmentCapitalOnYear(x);
                res[i] = isNaN(num_contender) ? 0 : num_contender;
            }

            return res;
        },

        //Calcs retirement age from investments
        setInvestmentsNeedsIntersection(current_age, needs_plot, investments_plot) {
            this.investment_age = -1;
            for(let i = 0; i < investments_plot.length; i++) {
                if(investments_plot[i] >= needs_plot[i]) {
                    this.investment_age = current_age + i;
                    break;
                }
            }
        },

        //Get total capital evolution plot based on evolution of both savings and investments
        getSavingsAndInvestmentsYPlot: function(savingsYPlot, investmentsYPlot, needsYPlot, current_age) {
            const res = [];

            for(let i = 0; i < savingsYPlot.length; i++) {
                res[i] = savingsYPlot[i] + investmentsYPlot[i];
            }

            this.intersection_age = -1;
            for(let i = 0; i < res.length; i++) {
                if(res[i] >= needsYPlot[i]) {
                    this.intersection_age = current_age + i;
                    break;
                }
            }

            return res;
        },

        createTable: function() {

            var result = setInterval(() => {
                    if(document.getElementById("rc-output-c") != undefined && document.getElementById("rc-output-c") != null){
                        window.scrollTo(0, document.body.scrollHeight);
                        clearInterval(result);
                    }
            }, 200);

            const retirement_needs_y_plot = this.getRetirementNeedsYPlot(
                this.current_age,
                this.life_expectancy,
                this.annual_expenses
            );

            const current_capital_savings_y_plot = this.getSavingsCapitalYPlot(
                this.current_age,
                this.life_expectancy,
                this.init_capital,
                this.annual_capital,
                this.annual_expenses,
                this.annual_inflation
            );

            const current_capital_investments_y_plot = this.getInvestmentCapitalYPlot(
                this.current_age,
                this.life_expectancy,
                this.init_investment,
                this.annual_investment,
                this.annual_returns,
                this.annual_investment_costs,
                this.annual_inflation
            );

            const current_capital_investments_and_savings_y_plot = this.getSavingsAndInvestmentsYPlot(
                current_capital_savings_y_plot,
                current_capital_investments_y_plot,
                retirement_needs_y_plot,
                this.current_age
            );

            this.setSavingsNeedsIntersection(
                this.current_age,
                retirement_needs_y_plot,
                current_capital_savings_y_plot
            );

            this.setInvestmentsNeedsIntersection(
                this.current_age,
                retirement_needs_y_plot,
                current_capital_investments_y_plot
            );

            Highcharts.chart('rc-chart-c', {

                title: {
                    text: ''
                },

                yAxis: {
                    title: {
                        text: 'Capital'
                    },
                },

                xAxis: {
                    title: {
                        text: 'Edad'
                    },
                    accessibility: {
                        rangeDescription: 'Range: 2010 to 2017'
                    }
                },

                legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle'
                },

                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false
                        },
                        pointStart: parseInt(this.current_age)
                    }
                },

                series: [
                    {
                        name: 'Capital necesario para jubilarse',
                        data: retirement_needs_y_plot
                    },
                    {
                        name: 'Capital de inversiones actual',
                        data: current_capital_investments_y_plot
                    },
                    {
                        name: 'Capital de ahorros actual',
                        data: current_capital_savings_y_plot
                    },
                    {
                        name: 'Capital de ahorros e inversiones',
                        data: current_capital_investments_and_savings_y_plot
                    }
                ],

                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                layout: 'horizontal',
                                align: 'center',
                                verticalAlign: 'bottom'
                            }
                        }
                    }]
                }

            });

            this.chart_generated = true;

        },
    }
}
</script>




<style lang="scss" scoped>

#rc-c * {
  font-family: 'Open Sans', sans-serif;
  color: var(--color-text-primary);
  background-color: var(--color-background);
}

h1 {
    margin-top: 50px;
}

#rc-input-c input {
    border: 1px solid var(--color-background-outline);
    border-radius: 4px;
    height: 36px;
    padding: 10px;
    width: 155px;
    display: block;
    font-size: 1rem;
}

#rc-input-submit-button {
    background-color: var(--color-accent-primary);
    color: var(--color-typography-on-accent);
    display: inline-block;
    padding: 12px 10px;
    text-align: center;
    border-radius: 1500px;
    cursor: pointer;
}

.rc-section-title {
    color: var(--color-typography-on-inactive);
}

.material-input {
    width: 250px;
    margin-bottom: 25px;
    margin-right: 30px;
}

@media (max-width: 1200px) {
    #rc-header-c {
        width: 90%;
        margin: 50px 5%;
    }

    .rc-input-section-c {
        width: 295px;
        margin: auto;
    }

    #rc-input-c input {
        width: 275px;
    }

    #rc-input-c span {
        margin-left: 10px;
    }

    #rc-input-c {
        text-align: left;
    }

    .rc-input-section-c {
        margin-bottom: 65px;
    }

    #rc-input-submit-button {
        margin: 0 auto;
    }

    #rc-form-c {
        text-align: center;
    }

    #rc-chart-c {
        margin-top: 30px;
    }

    #rc-output-c {
        width: fit-content;
        margin: 25px auto;
        padding: 25px;
    }

    .rc-section-title-container {
        margin-bottom: 20px;
    }

    .rc-text-input-c {
        height: 56px;
        margin-bottom: 30px;
    }

    .rc-section-title {
        margin-left: 0 !important;
    }

    #rc-output-c {
        display: flex;
        flex-direction: column;
        text-align: top;
        flex-grow: 1;
        text-align: center;
        margin-top: 44.5px;
        gap: 20px;
    }

    .rc-retire-text {
        text-align: left;
        background-color: var(--color-primary-050);
        padding: 15px 25px;
        margin: 0;
    }
}

@media (min-width: 1201px) {
    #rc-c > * {
        margin: 50px 5%;
        margin-top: 0;
    }

    #rc-input-c input {
        width: 225px;
        margin-bottom: 5px;
    }

    #rc-input-c span {
        display: inline-block;
        width: 225px;
        margin-left: 10px;
    }

    #rc-input-c {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 600px;
        margin-bottom: 50px;
    }

    .rc-text-input-c {
        display: inline-block;
        height: 56px;
        margin-bottom: 25px;
        margin-right: 30px;
    }

    #rc-form-c {
        display: inline-block;
    }

    #rc-input-submit-button {
        width: 175px;
    }

    #rc-input-submit-button {
        margin: 0 auto;
    }

    #rc-chart-c {
        margin: 0;
        padding: 50px;
        background-color: var(--color-background);
    }

    #rc-output-c {
        display: flex;
        flex-direction: column;
        text-align: top;
        flex-grow: 1;
        text-align: center;
        margin-top: 44.5px;
        gap: 20px;
    }

    #rc-output-c p {
        background-color:var(--color-surface);
        color:var(--color-text-secondary);
    }

    .rc-retire-text {
        text-align: left;
        background-color: var(--color-primary-050);
        padding: 40px 60px;
        margin: 0;
    }

    #rc-output-age {

    }

    #rc-input-output-c {
        display:flex;
    }

    .rc-input-group {
        display: flex;
        flex-wrap: wrap;
    }

    .rc-section-title {
        margin-bottom: 25px;
        margin-left: 0 !important;
    }
}

</style>
