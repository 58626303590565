<template>
    <label :id="id + '-component'" class="mdc-text-field mdc-text-field--outlined">
    <span class="mdc-notched-outline">
        <span class="mdc-notched-outline__leading"></span>
        <span class="mdc-notched-outline__notch">
        <span class="mdc-floating-label" :id="id"> {{label}} </span>
        </span>
        <span class="mdc-notched-outline__trailing"></span>
    </span>
    <input
    v-model="input_value"
    type="text"
    class="mdc-text-field__input"
    :aria-labelledby="id">
    </label>
</template>
<script>
    import {MDCTextField} from '@material/textfield';
    export default {
        name: 'DigitOutlineInput',
        data() {
            return {
                input_value: '',
                text_field: null,
            };
        },
        props: {
            id: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: true
            }
        },
        watch: {
            input_value(new_val, old_val) {
                const max_size = 15;
                const new_val_is_valid_digit = /^\d*[.,]?\d*$/.test(new_val) && new_val.length <= max_size;
                if(new_val_is_valid_digit) {
                    const formatted_new_val = new_val.replace(',', '.');
                    this.input_value = formatted_new_val;
                    this.$emit('inputChanged', formatted_new_val);
                }
                else {
                    this.input_value = old_val;
                }
            }
        },
        mounted() {
            this.text_field = new MDCTextField(document.querySelector('#' + this.id + '-component'));
        },
        methods: {
            handleKeyPress: function(event) {
                const maxSize = 15;
                const is_max_size_input_string = this.input_value.length >= maxSize;
                const is_already_float_current_string = this.input_value.indexOf('.') != -1;
                const is_dottable_char_inputted = event.key == ',' || event.key == '\'';
                const is_dot_inputted = event.key == '.';
                const is_digit_inputted = /\d/.test(event.key);

                if(is_max_size_input_string || (!is_digit_inputted && !is_dot_inputted && !is_dottable_char_inputted) || (is_already_float_current_string && (is_dot_inputted || is_dottable_char_inputted))) {
                    event.preventDefault();
                }
                else if(!is_digit_inputted && is_dottable_char_inputted) {
                    event.preventDefault();
                    this.input_value += '.';
                }
            }
        }
  }
</script>
<style lang="scss" scoped>
@use "@material/floating-label/mdc-floating-label";
@use "@material/line-ripple/mdc-line-ripple";
@use "@material/notched-outline/mdc-notched-outline";
@use "@material/textfield";
@include textfield.core-styles;

.mdc-text-field {
    @include textfield.disabled-fill-color(#F3F4F7);
    @include textfield.ink-color(var(--color-text-primary));
    @include textfield.label-color(var(--color-text-primary)); //color del label que esta en el input y que luego se pone arriba
    @include textfield.placeholder-color(var(--color-text-primary));
    @include textfield.disabled-ink-color(var(--color-text-primary));
    @include textfield.outline-color(var(--color-text-primary));//color del borde cuando no esta seleccionado
    @include textfield.outline-color(var(--color-text-primary));
    @include textfield.hover-outline-color(var(--color-text-primary));  // Add this line
    --mdc-theme-primary: #0E5F85FF; //con esta linea cambiamos el borde del input cuando esta seleccionado.
}
</style>